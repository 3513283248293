import React, { useContext, useEffect } from "react"

import Layout from "../../Layout/index"
import EpharmacyCart from "../Cart/index"
import Epharmacy from "../index"

import { AppContext } from "../../../context/AppContext"
import { EpharmacyContext } from "../EpharmacyContext/EpharmacyContext"
import useNovartisProducts from "./hooks/useNovartisProducts"

const EpharmacyOrder = ({ pageContext, location }) => {
  const { module } = pageContext
  const { epharmacyState, epharmacyDispatch } = useContext(EpharmacyContext)
  const { dispatch } = useContext(AppContext)

  const data = useNovartisProducts()
  let dataMap = data.allAirtableallNovartisProducts.nodes.map(
    (node) => node.data
  )
  // transform data to fit search format
  dataMap = dataMap.map(parseAirtableData)

  useEffect(() => {
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        heading: "Pulse Care Program Update",
        isCard: true,
        headerClass: `has-text-info has-background-info-light has-text-weight-bold is-size-5`,
        content: (
          <div className="has-text-left">
            <p className="is-size-6">
              Good news! The Pulse Care Program is continuing until&nbsp;
              <span className="has-text-weight-bold">April 30, 2025&nbsp;</span>
              You can continue to order your Sacubitril-Valsartan as we finalize
              the new scheme to ensure a smooth transition. If you have any
              questions, please contact us at pulsecare@medgrocer.com or
              09178482088.
            </p>
            <div className="buttons is-centered mt-2">
              <button
                className="button is-primary is-outlined"
                onClick={() => dispatch({ type: "HIDE_MODAL" })}
              >
                Close
              </button>
            </div>
          </div>
        ),
      },
    })
  }, [])

  return (
    <Layout {...module} path={location.pathname} pageContext={pageContext}>
      <Epharmacy
        pageContext={pageContext}
        cartContainer={
          <EpharmacyCart
            open={{
              documentsCard: false,
              medicinesCard: true,
            }}
            state={epharmacyState}
            dispatch={epharmacyDispatch}
          />
        }
        data={dataMap}
        lookupKeys={["brand", "DrugName"]}
        moduleState={epharmacyState}
        moduleDispatch={epharmacyDispatch}
      />
    </Layout>
  )
}

const parseAirtableData = (element) => {
  let jsonFormat = {
    brand: [element?.brand],
    DrugName: element?.drugName,
    productTitle: element?.productTitle,
    form: element?.form?.[0],
    molecule: element?.molecule?.[0],
    strength: element?.strength?.[0],
    rxRequired: element?.rxRequired,
    price_list: {
      unitPrice: element?.unitPrice?.[0],
      vat: element?.vat?.[0],
      vatex_unit_price: element?.vatexUnitPrice?.[0],
    },
  }

  return jsonFormat
}

export default EpharmacyOrder
